import React from 'react';

import { isAdmin } from 'utils/userHelpers';
import { useCommunityContext } from 'components/CommunityContext';
import { useSiteFeaturesContext } from 'components/SiteFeaturesContext';

interface FeatureCloakProps {
  admins?: boolean;
  flags?: string[];
  communityEvents?: boolean;
  communityEventNotifications?: boolean;
}

const FeatureCloak: React.FC<FeatureCloakProps> = ({
  admins,
  flags,
  communityEventNotifications,
  communityEvents,
  children,
}) => {
  const { currentUser, featureFlags } = useCommunityContext();
  const { siteFeatures } = useSiteFeaturesContext();

  if (flags) {
    const anyFlagsDisabled = flags.some((flag) => {
      return !featureFlags[flag];
    });
    if (anyFlagsDisabled) return null; // Hide if any of the flags are disabled
  }

  if (typeof communityEventNotifications === 'boolean') {
    // Only show if communityEventNotifications.isEnabled matches the passed boolean
    if (
      siteFeatures?.communityEventNotifications?.isEnabled !==
      communityEventNotifications
    ) {
      return null;
    }
  }

  if (typeof communityEvents === 'boolean') {
    // Only show if communityEvents.isEnabled matches the passed boolean
    if (siteFeatures?.communityEvents?.isEnabled !== communityEvents) {
      return null;
    }
  }

  if (typeof admins === 'boolean') {
    if (isAdmin(currentUser) !== admins) {
      return null;
    }
  }

  return <>{children}</>;
};

export default FeatureCloak;
