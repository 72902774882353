import React, { Dispatch, SetStateAction, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Button, Icon, IconButton } from '@thinkific/toga-react';

import { canAccess, isAdmin } from 'utils/userHelpers';
import { LIVE_EVENTS_LIST_QUERY } from 'schema/Event/queries';
import { genericToastAlert } from 'utils/toastHelpers';
import {
  SCREEN_PHONE,
  NUMBER_OF_EVENTS_ON_HOME,
  USER_ROLES,
} from 'utils/constants';
import { P } from 'koba/components/Typography';
import { useCommunityContext } from 'components/CommunityContext';
import EventCard from 'components/Events/components/EventCard/EventCard';

import { useWindowSize } from 'hooks/useWindowSize';
import { goToEvents, goToNewEvent } from 'utils/routerHelpers';

import {
  LiveEventsQuery,
  LiveEventsQueryVariables,
  EventStatusTypes,
} from '__generated__/graphql/legacy/graphql';

import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';
import EventCardInteractionWrapper from 'components/Events/components/EventCardInteractionWrapper';
import EventAdoptionPanel from '../EventAdoptionPanel';

import styles from './UpcomingEvents.module.scss';

const cx = classNames.bind(styles);

interface UpcomingEventsProps {
  shouldDisplayHeader?: boolean;
  numberOfEvents?: number;
  setHideComponent?: Dispatch<SetStateAction<boolean>>;
}

const UpcomingEvents: React.FC<UpcomingEventsProps> = ({
  shouldDisplayHeader = true,
  numberOfEvents = NUMBER_OF_EVENTS_ON_HOME,
  setHideComponent,
}) => {
  const router = useRouter();

  const { community, currentUser, trackEvent } = useCommunityContext();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width < SCREEN_PHONE;

  const { eventId: eventIdParam } = router.query;

  const { loading, data } = useQuery<LiveEventsQuery, LiveEventsQueryVariables>(
    LIVE_EVENTS_LIST_QUERY,
    {
      variables: {
        eventSourceId: community.id,
        eventStatus: EventStatusTypes.Upcoming,
        numberOfEvents,
      },
      fetchPolicy: 'cache-first',
      onError: () => {
        genericToastAlert();
      },
    }
  );

  const events = data?.site?.liveEvents;
  let eventsEdges = events?.edges;

  if (eventIdParam) {
    eventsEdges = eventsEdges
      ?.filter((edge) => edge?.node?.id !== eventIdParam)
      .slice(0, NUMBER_OF_EVENTS_ON_HOME);
  }

  const goToCreateEventPage = () => {
    trackEvent(TrackingEvent.CLICK_CREATE_LIVE_EVENT, {
      [TrackingProperty.COMPONENT]: 'UpcomingEvents',
    });

    goToNewEvent(router, community.id);
  };

  const goToEventsPage = () => {
    trackEvent(TrackingEvent.CLICK_SEE_ALL_EVENTS);
    goToEvents(router, community.id);
  };

  useEffect(() => {
    if (setHideComponent) {
      setHideComponent(eventsEdges?.length === 0 || false);
    }
  }, [eventsEdges?.length]);

  const createEventButton = canAccess(
    [
      USER_ROLES.SITE_OWNER_SITE_ADMIN,
      USER_ROLES.SITE_ADMIN,
      USER_ROLES.PARTNER,
    ],
    currentUser
  ) && (
    <>
      {isMobile ? (
        <IconButton
          appearance="utility"
          aria-label={t('shared-CREATE_EVENT', 'CREATE EVENT')}
          className={cx('create-event__button')}
          data-qa="create-event__icon-button"
          name="plus"
          title={t('shared-CREATE_EVENT', 'CREATE EVENT')}
          onClick={goToCreateEventPage}
        />
      ) : (
        <Button
          appearance="knockout"
          className={cx('create-event__button')}
          data-qa="create-event__button"
          onClick={goToCreateEventPage}
        >
          {t('shared-CREATE_EVENT', 'CREATE EVENT')} <Icon name="plus" />
        </Button>
      )}
    </>
  );

  const seeAllButton = events?.pageInfo?.hasNextPage && (
    <Button
      appearance="secondary"
      data-qa="see-all__button"
      onClick={goToEventsPage}
    >
      {t('SEE ALL')}
    </Button>
  );

  return (
    <div data-loading={loading} data-qa="upcoming-events">
      {!loading && (
        <>
          {shouldDisplayHeader && (
            <div
              className={cx('upcoming-events__header')}
              data-qa="upcoming-events-header"
            >
              {((!eventsEdges?.length && isAdmin(currentUser)) ||
                !!eventsEdges?.length) && (
                <>
                  <P className={cx('upcoming-events__header-title')}>
                    {t(
                      'components-events-upcomingEvents-UPCOMING_EVENTS',
                      'UPCOMING EVENTS'
                    )}
                  </P>
                  <div className={cx('upcoming-events__header-buttons')}>
                    {createEventButton}
                    {!isMobile && seeAllButton}
                  </div>
                </>
              )}
            </div>
          )}
          {eventsEdges?.length === 0 && (
            <VisibilityCloak admins>
              <EventAdoptionPanel goToCreateEventPage={goToCreateEventPage} />
            </VisibilityCloak>
          )}
          <div className={cx('upcoming-events__list')}>
            {eventsEdges?.map((edge) => {
              const node = edge?.node;

              if (node) {
                return (
                  <EventCardInteractionWrapper
                    dataQA="upcoming-events__list-card"
                    eventId={node.id}
                    key={node.id}
                  >
                    <EventCard
                      endTime={node.endTime}
                      id={node.id}
                      imageAltText={node.imageAltText}
                      imageUrl={node.imageUrl}
                      interestedUserCount={node.interestedUsers?.totalCount}
                      isInterested={node.isInterested || false}
                      startTime={node.startTime}
                      timezone={currentUser?.profile?.timezone}
                      title={node.title}
                    />
                  </EventCardInteractionWrapper>
                );
              }

              return null;
            })}
            {isMobile && seeAllButton}
          </div>
        </>
      )}
    </div>
  );
};

export default UpcomingEvents;
