import React, { useRef } from 'react';
import PubSub from 'pubsub-js';
import classNames from 'classnames/bind';

import { useRouter } from 'next/router';
import { useWindowSize } from 'hooks/useWindowSize';
import { useCommunityContext } from 'components/CommunityContext';
import { rawId } from 'utils/rawId';

import { EventStatusTypes } from '__generated__/graphql/legacy/graphql';
import { a11yOnClick } from 'helpers/interactions';

import { eventNames } from 'constants/eventNames';
import { SCREEN_LG } from 'utils/constants';
import { goToEvent } from 'utils/routerHelpers';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { togaFooterBlockName } from 'toga/Card';
import { isAdmin } from 'utils/userHelpers';
import styles from './EventCardInteractionWrapper.module.scss';

const cx = classNames.bind(styles);

interface EventCardInteractionWrapperProps {
  eventStatus?: EventStatusTypes;
  eventId: string;
  dataQA: string;
}

const EventCardInteractionWrapper: React.FC<EventCardInteractionWrapperProps> = ({
  children,
  eventStatus,
  eventId,
  dataQA,
}) => {
  const router = useRouter();
  const { community, trackEvent, currentUser } = useCommunityContext();
  const eventCardRef = useRef(null);

  const isPastEvent = eventStatus === EventStatusTypes.Expired;

  const { width } = useWindowSize();
  const isMobile = width < SCREEN_LG;

  const handleOpenEventPanel = (e, id: string) => {
    e.preventDefault();

    if (isMobile) {
      e.stopPropagation();
      goToEvent(router, id, community.id);
    } else {
      trackEvent(TrackingEvent.OPEN_LIVE_EVENT_DETAILS_TAB, {
        [TrackingProperty.LIVE_EVENT_ID]: rawId(id || ''),
        [TrackingProperty.LIVE_EVENT_ID_ENCODED]: id,
      });

      PubSub.publish(eventNames.OPEN_EVENT_PANEL, {
        eventId,
        lastElementRef: eventCardRef, // Doesn't seem to work with keyboard navigation
      });
    }
  };

  return (
    <div
      className={cx('card', {
        'is-past-event': isPastEvent && !isAdmin(currentUser), // The opacity causes issues with the actions dropdown
      })}
      data-qa={dataQA}
      ref={eventCardRef}
      {...a11yOnClick<HTMLElement>((e) => {
        const isTargetWithinFooter = (e.target as HTMLElement).closest(
          `[class*="${togaFooterBlockName}"]`
        );
        if (!isTargetWithinFooter) {
          handleOpenEventPanel(e, eventId);
        }
      })}
    >
      {children}
    </div>
  );
};

export default EventCardInteractionWrapper;
