import { useState } from 'react';

interface UseToggleReturn<T> {
  activeItem: T;
  handleToggle: (item: T) => void;
}

function useToggle<T>(
  defaultActiveItem: T,
  callback?: () => void
): UseToggleReturn<T> {
  const [activeItem, setActiveItem] = useState<T>(defaultActiveItem);

  const handleToggle = (item: T) => {
    setActiveItem(item);
    callback?.();
  };

  return {
    activeItem,
    handleToggle,
  };
}

export default useToggle;
