import React, { MouseEvent } from 'react';
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Icon } from '@thinkific/toga-react';

import { P } from 'koba/components/Typography';
import { goToSpaces } from 'utils/routerHelpers';
import { genericToastAlert } from 'utils/toastHelpers';
import { SECTION_QUERY } from 'schema/Section/queries';
import { Space } from 'interfaces/space';
import { useCommunityContext } from 'components/CommunityContext';
import { SpaceDetailsCard } from 'components/SpaceDetails';
import { features } from 'constants/featureFlags';

import { useRouter } from 'next/router';
import styles from './SpaceCardsList.module.scss';

const cx = classNames.bind(styles);

const SpaceCardsList: React.FC = () => {
  const router = useRouter();
  const { community, currentUser, featureFlags } = useCommunityContext();
  const { t } = useTranslation();
  const isCoursesToCommunitiesEnabled =
    featureFlags[features.COURSES_TO_COMMUNITIES];

  const { loading, data } = useQuery(SECTION_QUERY, {
    variables: { communityId: community.id, isCoursesToCommunitiesEnabled },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      genericToastAlert();
    },
  });

  let spaces = [];

  if (data?.community) {
    spaces =
      data.community.communitySections.edges[0].node.communitySpaces.edges;
  }

  const handleClick = (event: MouseEvent, filter: string) => {
    event.preventDefault();
    goToSpaces(router, filter, community.id, true);
  };

  /**
   * ToDo: PPG-1655 (https://thinkific.atlassian.net/browse/PPG-1655)
   * remove once we can get exclusive space members from query
   */
  const isExclusiveMember = false;

  return (
    <>
      {!loading && (
        <>
          <P className={cx('space-cards-list__title')}>{t('ALL SPACES')}</P>

          <div className={cx('space-cards-list__wrapper')}>
            {spaces.map(
              // eslint-disable-next-line react/no-unused-prop-types
              ({ node: space }: { node: Space }) => {
                const totalPosts =
                  space?.posts &&
                  new Intl.NumberFormat(currentUser.locale || 'en', {
                    notation: 'compact',
                  }).format(space.posts.totalCount);

                return (
                  <a
                    aria-label={t('Go to {{space}}', {
                      space: space.name,
                    })}
                    className={cx('space-cards-list__anchor')}
                    href={`/communities/${community.id}/spaces/${space.id}`}
                    key={space.id}
                    onClick={(event: MouseEvent) =>
                      handleClick(event, space.id)
                    }
                  >
                    <SpaceDetailsCard
                      className={cx('space-cards-list__details')}
                      description={space?.description}
                      footerAccessory={
                        <span
                          className={cx('space-cards-list__footer')}
                          data-qa="space-card-total-posts__text"
                        >
                          <Icon name="message" />
                          {space?.posts?.totalCount === 1
                            ? t('{{total}} post', { total: totalPosts })
                            : t('{{total}} posts', { total: totalPosts })}
                        </span>
                      }
                      icon={space?.iconName}
                      imageAltText={space?.imageAltText}
                      imageUrl={space?.imageUrl}
                      isExclusiveMember={isExclusiveMember}
                      name={space?.name}
                    />
                  </a>
                );
              }
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SpaceCardsList;
