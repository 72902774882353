import React from 'react';
import { useQuery } from '@apollo/client';
import { USERS_QUERY } from 'schema/User/queries';
import {
  UsersQuery,
  UsersQueryVariables,
} from '__generated__/graphql/legacy/graphql';
import { useCommunityContext } from 'components/CommunityContext';

import { useTranslation } from 'react-i18next';
import CommunityUsersSkeleton from './components/CommunityUsersSkeleton';
import CommunityUsersAvatars from './components/CommunityUsersAvatars';

interface CommunityUser {
  id: string;
  fullName: string;
  profile: {
    avatarUrl: string;
  };
}

const CommunityUsers: React.FC = () => {
  const { t } = useTranslation();
  const { community, currentUser } = useCommunityContext();

  const { data, loading } = useQuery<UsersQuery, UsersQueryVariables>(
    USERS_QUERY,
    {
      variables: {
        communityId: community.id,
        numberOfUsers: 4,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const totalUsers = data?.community?.communityUsers?.totalCount || 0;
  const communityUsersEdges = data?.community?.communityUsers?.edges;

  let filteredUsers = [] as CommunityUser[];

  filteredUsers =
    communityUsersEdges
      ?.map((edge) => {
        return {
          id: edge?.node?.user?.id || '',
          fullName: edge?.node?.user?.fullName || '',
          profile: {
            avatarUrl: edge?.node?.user?.profile?.avatarUrl || '',
          },
        };
      })
      .filter((user) => user.id !== currentUser.id) || [];

  // Makes sure the first UserAvatar shown is the current user logged
  filteredUsers?.unshift({
    id: currentUser.id,
    fullName: currentUser.fullName,
    profile: { avatarUrl: currentUser.avatarUrl },
  });

  // if filteredUsers length is bigger than 4 it means that the currentUser
  // wasn't returned by the USERS_QUERY and we need to display it + 3 users
  if (filteredUsers.length > 4) filteredUsers = filteredUsers.slice(0, 4);

  return (
    <>
      {loading ? (
        <CommunityUsersSkeleton />
      ) : (
        <CommunityUsersAvatars
          tooltipTitle={t(
            'components-communityUsers-total_member_count_title',
            '{{communityName}} has {{userCount}} members',
            { communityName: community.name, userCount: totalUsers }
          )}
          totalUsers={totalUsers}
          users={filteredUsers}
        />
      )}
    </>
  );
};

export default CommunityUsers;
