import React from 'react';
import { FC } from 'types';

import HomeFeed from 'components/HomeFeed';

import { getLayout } from 'components/Layouts/MainLayout';
import { useCommunityContext } from 'components/CommunityContext';
import { useTranslation } from 'react-i18next';
import CommunityHeader from 'components/CommunityHeader/CommunityHeader';

interface IndexPageProps {
  isFollowingPage?: boolean;
  emptyTitle?: string;
  emptyMessage?: string;
  postListState: {
    useCacheFirst: boolean;
  };
}

const IndexPage: FC<IndexPageProps> = ({ postListState }) => {
  const { t } = useTranslation();
  const { community } = useCommunityContext();

  return (
    <>
      <CommunityHeader pageTitle={t('pages-home_title', 'Home')} />
      <HomeFeed community={community} postListState={postListState} t={t} />
    </>
  );
};

IndexPage.propTypes = {};

IndexPage.getLayout = getLayout;

export default IndexPage;
