import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { P } from '@thinkific/toga-react';

import { SCREEN_LG } from 'utils/constants';
import { useCommunityContext } from 'components/CommunityContext';
import { useWindowSize } from 'hooks/useWindowSize';
import Button, { ButtonProps } from 'koba/components/Button';
import ExplanationCard from 'components/HomeFeed/components/ExplanationCard';
import PostList from 'components/PostList';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import useToggle from 'hooks/useToggle';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';

import styles from './HomePosts.module.scss';

const cx = classNames.bind(styles);

// eslint-disable-next-line no-shadow
enum ToggleItems {
  LATEST = 'LATEST',
  TRENDING = 'TRENDING',
}

interface HomePostsProps {
  postListState: {
    useCacheFirst: boolean;
  };
  openModal: () => void;
}

const HomePosts: React.FC<HomePostsProps> = ({ postListState, openModal }) => {
  const { community, trackEvent } = useCommunityContext();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width < SCREEN_LG;
  const { activeItem, handleToggle } = useToggle<ToggleItems>(
    ToggleItems.LATEST
  );
  const isTrendingPosts = activeItem === ToggleItems.TRENDING;

  useEffect(() => {
    // If user resizes to a desktop size toggle selection to LATEST
    // so, when they resize back to a small screen size it shows latest posts as active
    if (!isMobile && isTrendingPosts) handleToggle(ToggleItems.LATEST);
  }, [isMobile]);

  const setButtonProps = (isActive: boolean): Partial<ButtonProps> => {
    if (isActive) {
      return {
        isActive: true,
        color: community.primaryColor,
        appearance: 'default',
      };
    }

    return {
      isActive: false,
      appearance: 'ghost',
    };
  };

  const handleClickOnPostCTA = () => {
    trackEvent(TrackingEvent.POST_MODAL_OPENED, {
      [TrackingProperty.COMPONENT]: 'HomePosts',
    });

    openModal();
  };

  const latestPostsEmptyState = () => (
    <ExplanationCard
      isTrending={isTrendingPosts}
      onClick={handleClickOnPostCTA}
    />
  );

  return (
    <>
      <VisibilityCloak hide={isMobile}>
        <P className={cx('home-posts__title')}>{t('LATEST POSTS')}</P>
      </VisibilityCloak>

      <VisibilityCloak hide={!isMobile}>
        <div className={cx('home-posts__filters')}>
          <Button
            appearance="ghost"
            className={cx('home-posts__filters-latest')}
            dataQA="latest__button"
            onClick={() => handleToggle(ToggleItems.LATEST)}
            {...setButtonProps(activeItem === ToggleItems.LATEST)}
          >
            {t('common-LATEST', 'LATEST')}
          </Button>
          <Button
            appearance="ghost"
            className={cx('home-posts__filters-trending')}
            dataQA="trending__button"
            onClick={() => handleToggle(ToggleItems.TRENDING)}
            {...setButtonProps(isTrendingPosts)}
          >
            {t('common-TRENDING', 'TRENDING')}
          </Button>
        </div>
      </VisibilityCloak>

      <PostList
        customEmptyState={latestPostsEmptyState()}
        emptyMessage={t(
          'components-homeFeed-post_list_empty_message',
          'Start a discussion by creating a new post in a Space.'
        )}
        emptyTitle={t("Looks like you'll be the first!")}
        isTrending={isTrendingPosts}
        sharedState={postListState}
        t={t}
        shouldShowTagOnPost
      />
    </>
  );
};

export default HomePosts;
