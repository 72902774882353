import React, { useState } from 'react';
import { TFunction } from 'next-i18next';

import Hero from 'components/Hero';
import SpaceCardsList from 'components/SpaceCardsList';
import UpcomingEvents from 'components/Events/components/UpcomingEvents';
import CreatePostCallToAction from 'components/CreatePostCallToAction';
import HomePosts from 'components/HomePosts/HomePosts';
import { features } from 'constants/featureFlags';
import CommunityChecklist from 'components/CommunityChecklist';
import { SCREEN_LG } from 'utils/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';
import FeatureCloak from 'components/VisibilityHelpers/FeatureCloak';
import { useCommunityContext } from 'components/CommunityContext';

interface HomeFeedProps {
  community: {
    published: boolean;
    primaryColor: string;
    description: string;
    imageUrl?: string;
    name: string;
  };
  t: TFunction;
  postListState: {
    useCacheFirst: boolean;
  };
}

const HomeFeed: React.FC<HomeFeedProps> = ({ t, community, postListState }) => {
  const { featureFlags } = useCommunityContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width } = useWindowSize();
  const isTabletOrSmaller = width < SCREEN_LG;
  const isCommunitiesAsAProductEnabled =
    featureFlags[features.COMMUNITIES_AS_A_PRODUCT];

  const shouldShowCommunitiesChecklist =
    !community.published && isTabletOrSmaller && isCommunitiesAsAProductEnabled;

  return (
    <>
      <Hero
        description={community.description}
        src={community.imageUrl}
        title={community.name}
      />
      <CreatePostCallToAction
        ariaLabel={t(
          'components-homeFeed-call_to_action_aria_label',
          'Click to create a post'
        )}
        dataQA="home-create-post__textfield"
        inputId="home-create-post__textfield-id"
        isModalOpen={isModalOpen}
        placeholderText={t(
          'components-homeFeed-write_a_post_placeholder_text',
          `What’s on your mind?`
        )}
        setIsModalOpen={setIsModalOpen}
      />
      {shouldShowCommunitiesChecklist && (
        <VisibilityCloak admins>
          <CommunityChecklist />
        </VisibilityCloak>
      )}
      <FeatureCloak communityEvents>
        <UpcomingEvents />
      </FeatureCloak>

      <HomePosts
        openModal={() => setIsModalOpen(true)}
        postListState={postListState}
      />

      <SpaceCardsList />
    </>
  );
};

export default HomeFeed;
