import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Row } from '@thinkific/toga-react';
import { Col } from 'toga';

import { useCommunityContext } from 'components/CommunityContext';
import { EVENT_LEARN_MORE_LINK } from 'utils/constants';
import Button from 'koba/components/Button';
import useLocalStorage from 'hooks/useLocalStorage';

import InfoCard from 'components/InfoCard';
import AdminOnlyIndicator from 'components/AdminOnlyIndicator';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';
import LearnMoreLink from 'components/LearnMoreLink';
import styles from './EventAdoptionPanel.module.scss';
import MiniEventPreviewCard from '../MiniEventPreviewCard/MiniEventPreviewCard';

const cx = classNames.bind(styles);

interface EventAdoptionPanelProps {
  goToCreateEventPage: () => void;
}

const EventAdoptionPanel: React.FC<EventAdoptionPanelProps> = ({
  goToCreateEventPage,
}) => {
  const EVENT_ADOPTION_KEY = 'hideEventAdoptionPanel';
  const { community } = useCommunityContext();
  const { isSaved } = useLocalStorage(EVENT_ADOPTION_KEY);
  const { t } = useTranslation();
  const [hideAdoptionPanel, setHideAdoptionPanel] = useState<boolean>(isSaved);

  return (
    <>
      {hideAdoptionPanel ? (
        <div
          className={cx('event-adoption__hint-container')}
          data-qa="event-adoption__hint-container"
        >
          <p className={cx('event-adoption__hint-text')}>
            {t(
              'components-events-eventsAdoptionPanel-events_adoption_hint',
              'Increase student engagement with live events.'
            )}
          </p>
          <LearnMoreLink href={EVENT_LEARN_MORE_LINK} padLeft />
        </div>
      ) : (
        <InfoCard
          dataQA="event-adoption__panel"
          localStorageKey={EVENT_ADOPTION_KEY}
          title={t(
            'components-events-eventsAdoptionPanel-events_adoption_header',
            'Host live events in your Community'
          )}
          isDismissable
          onClose={() => {
            setHideAdoptionPanel(true);
          }}
        >
          <Row>
            <Col lgColumns={8}>
              <div className={cx('mb-2')}>
                {t(
                  'components-events-eventsAdoptionPanel-events_adoption_subtext',
                  'Create dynamic, live learning experiences that engage your students and keep them coming back - now with invite emails and RSVP functionality.'
                )}
              </div>
              <div className={cx('mb-1')}>
                <Button
                  appearance="default"
                  aria-label={t('Go to create event form')}
                  className={cx('event-adoption__button-create')}
                  color={community.primaryColor}
                  data-qa="event-adoption__button-create"
                  onClick={goToCreateEventPage}
                >
                  {t('common-CREATE_EVENT', 'CREATE EVENT')}
                </Button>
                <Button
                  appearance="ghost"
                  aria-label={t('common-learn_more', 'Learn more')}
                  className={cx('event-adoption__button-learn-more')}
                  data-qa="event-adoption__button-learn-more"
                  href={EVENT_LEARN_MORE_LINK}
                  target="_blank"
                >
                  {t('common-LEARN_MORE', 'LEARN MORE')}
                </Button>
              </div>
            </Col>
            <Col lgColumns={4}>
              <VisibilityCloak hideMD>
                <MiniEventPreviewCard />
              </VisibilityCloak>
            </Col>
          </Row>
          <AdminOnlyIndicator />
        </InfoCard>
      )}
    </>
  );
};

export default EventAdoptionPanel;
