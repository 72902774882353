import React from 'react';
import classNames from 'classnames/bind';

import { SkeletonLine } from 'koba/components/SkeletonPost';

import styles from './CommunityUsersSkeleton.module.scss';

const cx = classNames.bind(styles);

export const CommunityUsersSkeleton: React.FC = () => (
  <>
    <div className={cx('community-users-skeleton')}>
      <div className={cx('community-users-skeleton__avatar')} />
      <div className={cx('community-users-skeleton__avatar')} />
      <div className={cx('community-users-skeleton__avatar')} />
      <div className={cx('community-users-skeleton__avatar')} />
      <div className={cx('community-users-skeleton__total')}>
        <SkeletonLine height="20px" width="25px" />
      </div>
    </div>
  </>
);

export default CommunityUsersSkeleton;
