import React from 'react';
import classNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';
import { Icon } from '@thinkific/toga-react';
import styles from './LearnMoreLink.module.scss';

const cx = classNames.bind(styles);

interface LearnMoreLinkProps {
  className?: string;
  href: string;
  iconName?: string;
  padLeft?: boolean;
  text?: string;
}

const LearnMoreLink: React.FC<LearnMoreLinkProps> = ({
  className,
  href,
  iconName = 'new-window',
  padLeft = false,
  text,
}) => {
  const { t } = useTranslation();
  const additionalClassName = padLeft ? 'pad-left' : '';

  return (
    <>
      <a
        className={cx(className || '', 'learn-more__link', additionalClassName)}
        href={href}
        target="_blank"
      >
        {text || t('common-learn_more', 'Learn more')}
        <Icon name={iconName} />
      </a>
    </>
  );
};

export default LearnMoreLink;
