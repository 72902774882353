import React from 'react';
import classNames from 'classnames/bind';

import { EVENT_ADOPTION_IMAGE } from 'utils/constants';
import Image from 'components/Image';

import { useTranslation } from 'react-i18next';

import styles from './MiniEventPreviewCard.module.scss';

const cx = classNames.bind(styles);

// If we get rid of the text in this preview, we can remove this entire component
const MiniEventPreviewCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={cx('mini-event-preview-card')}>
      <Image
        className={cx('mini-event-preview-card__image')}
        height={590}
        src={EVENT_ADOPTION_IMAGE}
        width={850}
      />
      <div className={cx('mini-event-preview-card__content')}>
        <p className={cx('mini-event-preview-card__content__date')}>
          {t('example-event-date', 'January 1, 2:00 PM GMT-7')}
        </p>
        <p className={cx('mini-event-preview-card__content__title')}>
          {t('example-event-title', 'Weekly Group Q&A')}
        </p>
      </div>
    </div>
  );
};

export default MiniEventPreviewCard;
