import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  HOME_FEED_LATEST_POSTS_MODERATOR,
  HOME_FEED_LATEST_POSTS_STUDENT,
} from 'utils/constants';
import { useCommunityContext } from 'components/CommunityContext';
import { isAdmin } from 'utils/userHelpers';
import CreatePostCard from 'components/InfoCard/variations/CreatePostCard/CreatePostCard';

interface ExplanationCardProps {
  onClick: () => void;
  isTrending?: boolean;
}

const ExplanationCard: React.FC<ExplanationCardProps> = ({
  onClick,
  isTrending = false,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useCommunityContext();
  const userIsAdmin = isAdmin(currentUser);

  let latestPostsEmptyStateTitle = userIsAdmin
    ? t(
        'components-homeFeed-moderator_title',
        'Create your first post to welcome members to your Community'
      )
    : t(
        'components-homeFeed-student_title',
        'Jump in and say hello to your Community!'
      );
  let latestPostsEmptyStateContent = userIsAdmin
    ? t(
        'components-homeFeed-moderator_description',
        'Engage your students with icebreakers. Encourage them to introduce themselves, share their favourite online course, or post assignments for feedback from the community.'
      )
    : t(
        'components-homeFeed-student_description',
        "You're in the right place with the right people. Get to know your fellow community members and give them a virtual wave by introducing yourself."
      );

  if (isTrending) {
    latestPostsEmptyStateTitle = userIsAdmin
      ? t(
          'components-homeFeed-trending_posts_moderator_title',
          'Trending posts'
        )
      : t(
          'components-homeFeed-trending_posts_student_title',
          'What’s on your mind?'
        );
    latestPostsEmptyStateContent = userIsAdmin
      ? t(
          'components-homeFeed-trending_posts_moderator_description',
          'Posts with the most engagement will show up here, driving even more visibility and momentum.'
        )
      : t(
          'components-homeFeed-trending_posts_student_description',
          'Share your ideas, questions and insights with your community.'
        );
  }

  const latestPostsEmptyStateImageSrc = userIsAdmin
    ? HOME_FEED_LATEST_POSTS_MODERATOR
    : HOME_FEED_LATEST_POSTS_STUDENT;

  return (
    <CreatePostCard
      content={latestPostsEmptyStateContent}
      dataQA="empty-state-latest-posts"
      imageSrc={latestPostsEmptyStateImageSrc}
      title={latestPostsEmptyStateTitle}
      onClick={onClick}
    />
  );
};

export default ExplanationCard;
