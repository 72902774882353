import React from 'react';
import classNames from 'classnames/bind';
import { Surface } from '@thinkific/toga-react';

import CommunityUsers from 'components/CommunityUsers';
import Image from 'components/Image';

import styles from './Hero.module.scss';

const cx = classNames.bind(styles);

interface HeroProps {
  src?: string;
  title: string;
  description: string;
  alt?: string;
}

const Hero: React.FC<HeroProps> = ({ src, alt, title, description }) => {
  return (
    <Surface className={cx('hero__wrapper', 'mb-4')}>
      {src && (
        <Image
          alt={alt || ''}
          className={cx('border-top-radius')}
          height={160}
          src={src}
          width={640}
        />
      )}
      <div className={cx('hero__body', 'p-3')}>
        <div className={cx('hero__body-header')}>
          <h1 className={cx('hero__title', 'h3')} title={title}>
            {title}
          </h1>
          <CommunityUsers />
        </div>
        {description && (
          <div className={cx('hero__description', 'mt-2')} title={description}>
            {description}
          </div>
        )}
      </div>
    </Surface>
  );
};

export default Hero;
